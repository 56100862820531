import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { common } from '../config/enum';
import { environment } from '../config/environments/environment';
import { Title } from '@angular/platform-browser';
import { FormGroup, FormArray, FormControl } from '@angular/forms';
import { Location } from '@angular/common';
import { PaginationModel } from '../modules/shared/model/pagination-model';


@Injectable({
  providedIn: 'root'
})

export class CommonServiceService {
  AllowRead: boolean = false;
  AllowCreate: boolean = false;
  AllowUpdate: boolean = false;
  AllowDelete: boolean = false;
  appTitle: string;
  callToggle = new Subject();
  delete = new Subject();
  callTable = new Subject();
  multiDelete = new Subject();
  callToggleSkill = new Subject();
  callToggleAddEmp = new Subject();
  isactive = new Subject();
  departmentChange = new Subject();
  departmentName;
  codeChange = new Subject();
  code;
  mailChange = new Subject();
  mail;
  numberChange = new Subject();
  nameChange = new Subject();
  number;
  totalPage;
  page;
  pageNo = common.page;
  pageNo1 = common.page;
  pageNo2 = common.page;
  pageNo3 = common.page;
  pageNo4 = common.page;
  pageNo5 = common.page;
  pageNo6 = common.page;
  designationPage = common.page;
  qualificationpage = common.page;
  pageNo9 = common.page;
  size;
  isHeader;
  isHeader1;
  isHeader2;
  isHeader3;
  isHeader5;
  isHeader4;
  deleteButton;
  selectedIndex = 0;
  selectedEN_LocationTab = 0;
  selectedHC_LocationTab = 0;
  selectedSeatmeetingTab = 0
  searchText = '';
  subComponent = '';
  numberOfEmployeesToBeChoosen;
  subComponentToShow = new Subject<any>();
  search = new Subject<any>();
  searchCount = 0;
  searchCountSubject = new Subject<any>();
  httpOptions;
  sort = 'DESC';
  head = 'name';
  check = [];
  profileEdit = new Subject();
  profileEditOnClick = new Subject();
  editing = true;
  profileEditing = true;
  cipher;
  ipAddress = {};
  userId: any;
  userIdSubject = new Subject<any>();
  reportingManager;
  isRM = false;
  userInfo;
  firstName;
  middleName;
  lastName;
  reportingManagerIds = [];
  reportingManagerCheck = new Subject<any>();
  profileImage = '';
  profileImageToShow = new Subject<any>();
  savedprofileImage = '';
  savedprofileImageToShow = new Subject<any>();
  myAccountImage = '';
  myAccountImageToShow = new Subject<any>();
  userImage = new Subject<any>();
  userLogo = new Subject<any>();
  bulkUploadUnderProgressId: any;
  roleIdForResourceList = new Subject();
  permissionListForRole = new Subject();
  callListOfIncorrectRowsCsv = new Subject();

  closeFilter = new Subject();
  refreshToken = new Subject();
  reportingIds = [];
  reportingManagersNotShow = new Subject<any>();
  setCsvDeleteButtonVisible: boolean;
  TicketListingType = new Subject<any>();
  listingType = '';
  staffPersonalData: any = {};
  staffLocationData: any = {};
  staffData: any = {};
  headerHeight: any;
  sideBarWidth: any;
///DASHBOARD BRANCH

  private entitydashboard = new BehaviorSubject<string>('true');
  public ENTITYDB = this.entitydashboard.asObservable();
  editEntitydashboard(data){
    this.entitydashboard.next(data); 
  }

  private entityTheme = new BehaviorSubject<string>('true');
  public ENTITYTHEME = this.entityTheme.asObservable();
  changeEntityTheme(data){
    this.entityTheme.next(data); 
  }

  ///////

  constructor(private http: HttpClient,
    private titleService: Title,
    private location: Location) { }

  sendRefreshTokenEvent() {
    this.refreshToken.next();
  }
  getRefeshTokenEvent(): Observable<any> {
    return this.refreshToken.asObservable();
  }
  sendFilterCloseEvent() {
    this.closeFilter.next();
  }

  getFilterCloseEvent(): Observable<any> {
    return this.closeFilter.asObservable();
  }

  sendUserImage(image) {
    this.userImage.next({ img: image });
  }

  getUserImage(): Observable<any> {
    return this.userImage.asObservable();
  }

  sendUserLogo(logo) {
    localStorage.setItem('logoImg', logo);
    this.userLogo.next(logo);
  }

  getUserLogo(): Observable<any> {
    return this.userLogo.asObservable();
  }

  sendRoleIdForResourceList(id) {
    this.roleIdForResourceList.next({ roleId: id });
  }

  getRoleIdForResourceList(): Observable<any> {
    return this.roleIdForResourceList.asObservable();
  }

  sendPermissionListForRole(permissionList) {
    this.permissionListForRole.next({ list: permissionList });
  }

  getPermissionListForRole(): Observable<any> {
    return this.permissionListForRole.asObservable();
  }
  setAppTitle(title: string) {
    this.appTitle = title !== '' ? title : 'Caleedo';
    this.titleService.setTitle(this.appTitle);
  }

  resetAppTitle() {
    this.setAppTitle('');
  }

  sendSavedProfileImage() {
    this.savedprofileImageToShow.next();
  }
  getSavedProfileImage() {
    return this.savedprofileImageToShow.asObservable();
  }
  sendProfileImage() {
    this.profileImageToShow.next();
  }
  getProfileImage(): Observable<any> {
    return this.profileImageToShow.asObservable();
  }
  sendMyAccountImage() {
    this.myAccountImageToShow.next();
  }
  getMyaccountImage() {
    return this.myAccountImageToShow.asObservable();
  }
  sendRMIds() {
    this.reportingManagerCheck.next();
  }
  getRMIds(): Observable<any> {
    return this.reportingManagerCheck.asObservable();
  }

  sendReportingMIds() {
    this.reportingManagersNotShow.next();
  }
  getReportingMIds(): Observable<any> {
    return this.reportingManagersNotShow.asObservable();
  }

  sendSubComponent() {
    this.subComponentToShow.next();
  }
  getSubComponent(): Observable<any> {
    return this.subComponentToShow.asObservable();
  }

  sendUserId() {
    this.userIdSubject.next();
  }
  sendUserIdtoOther() {
    return this.userIdSubject.asObservable();
  }

  sendSearchEvent() {
    this.search.next();
  }

  getSearchEvent(): Observable<any> {
    return this.search.asObservable();
  }

  sendCountEvent() {
    this.searchCountSubject.next();
  }

  getCountEvent(): Observable<any> {
    return this.searchCountSubject.asObservable();
  }
  getCallIncorrectRowsCsvEvent(): Observable<any> {
    return this.callListOfIncorrectRowsCsv.asObservable();
  }
  sendCallIncorrectRowsCsvEvent() {
    this.callListOfIncorrectRowsCsv.next();
  }

  getUserInfoByUserId(userId) {
    return this.http.get(`${environment.apiUrl}api/v1/users/getAccountInformation?userId=${userId}`);
  }
  logout(userId) {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      }),
      body: {
        userId: userId,
        token: localStorage.getItem("user"),
        tabletUser:localStorage.getItem('customerAdminCode')==='TABLET'?true:false
      }
    }

    return this.http.delete(environment.newApiUrl + 'logout', options);
  }


  getRefreshToken(token) {
    return this.http.post(environment.newApiUrl + 'refreshToken', token)
  }

  markAsTouched(formObj) {
    if (formObj instanceof FormGroup) {
      for (const formGroupcontrol in formObj.controls) {
        this.markAsTouched(formObj.get(formGroupcontrol));
      }
    } else if (formObj instanceof FormArray) {
      for (let formArrayControl of formObj.controls) {
        this.markAsTouched(formArrayControl);
      }
    } else if (formObj instanceof FormControl) {
      formObj.markAsTouched();
      formObj.updateValueAndValidity();
    }
  }
  getActiveIcon() {
    return '/assets/images/check.webp';
  }
  getInActiveIcon() {
    return '/assets/images/cross.jpg';
  }
  sendListingEvent() {
    this.TicketListingType.next();
  }

  getListingEvent(): Observable<any> {
    return this.TicketListingType.asObservable();
  }
  b64EncodeUnicode(str: any) {
    return btoa(encodeURIComponent(str).replace(/%([0-9A-F]{2})/g,
      (match, p1) => {
        return String.fromCharCode(("0x" + p1) as any);
      }));
  }
  b64DecodeUnicode(str) {
    return decodeURIComponent(atob(str).split('').map(function (c) {
      return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));
  }
  setPermissions(value: String) {
    var count = 0;
    this.AllowRead = false;
    this.AllowCreate = false;
    this.AllowUpdate = false;
    this.AllowDelete = false;
    let array = localStorage.getItem('permissions')?JSON.parse(localStorage.getItem('permissions')):[];
    for (let i = 0; i < array.length; i++) {
      if (array[i].permission == 'SuperAdmin') {
        var body = {
          read: true,
          create: true,
          update: true,
          delete: true
        };
        return body;
      } else if (array[i].name == value) {
        count = 1;
        if (array[i].permission == 'R') {
          this.AllowRead = true;
        } else if (array[i].permission == 'C') {
          this.AllowCreate = true;
        } else if (array[i].permission == 'U') {
          this.AllowUpdate = true;
        } else if (array[i].permission == 'D') {
          this.AllowDelete = true;
        }
      }
    }
    if (count) {
      var body = {
        read: this.AllowRead,
        create: this.AllowCreate,
        update: this.AllowUpdate,
        delete: this.AllowDelete
      };
      return body;
    }
  }

  getStaffBasicInfo() {
    return this.http.get(`${environment.customerApiUrl}api/v1/staff`);
  }

  getLogoImg(customerId) {
    return this.http.get(environment.customerApiUrl + 'api/v1/customers/getCustomerById/' + customerId);
  }

  getAssetParameters(customerID){
    return this.http.get(`${environment.assetUrl}api/v1/assetcustomerdetail/getByCustomerId?customerId=${customerID}`);
  }

  getAllBranch() {
    return this.http.get(`${environment.authApiUrl}/api/v1/users/getAllBranch`);
  }

  getCustomerCountryCode(customerId) {
    return this.http.get(environment.customerApiUrl + 'api/v1/customers/getCustomerCountryCode/' + customerId);
  }

  getNotification(pageInfo: PaginationModel) {
    const userId = localStorage.getItem('LogoutId');
    return this.http.get(`${environment.tpBaseUrl}api/v1/notification?page=${pageInfo.
      page}&size=${pageInfo.size}&searchText=${encodeURIComponent(this.searchText)}&sortBy=${pageInfo.sortBy}&sortDirection=${pageInfo.sortDir}&userId=${userId}`);
  }

  getNotificationSWH(type) {
    const userId = localStorage.getItem('LogoutId');
    return this.http.get(`${environment.tpBaseUrl}api/v1/notification?userId=${userId}&type=${type}`);
  }

  getNotificationForHospital(pageInfo: PaginationModel){
    const userId = localStorage.getItem('LogoutId');
    return this.http.get(`${environment.tpBaseUrl}api/v1/notification/notify?page=${pageInfo.
      page}&size=${pageInfo.size}&searchText=${encodeURIComponent(this.searchText)}&sortBy=${pageInfo.sortBy}&sortDirection=${pageInfo.sortDir}&userId=${userId}`);
  }

  goBack() {
    this.location.back();
  }

  dataFilter(value, DataOptions) {
    const filterValue = value ? value.toLowerCase() : '';
    return value != '' ?
      DataOptions.filter(option => option.name.toLowerCase().includes(filterValue)) : DataOptions;
  }

  attendeeFilter(value, DataOptions) {
    const filterValue = value ? value.toLowerCase() : '';
    return value != '' ?
      DataOptions.filter(option => option.staffName.toLowerCase().includes(filterValue)) : DataOptions;
  }

  getAccessToken() {
    const token = localStorage.getItem('user');
    return token;
  }

  sendEmergencyEvacNotification(){
    const customerId = localStorage.getItem('customerId');
    return this.http.get(`${environment.visitorUrl}visitor/evaculation?customerId=${customerId}`);
  }


  //Entity

  centerFilter(value, DataOptions) {
    const filterValue = value ? value.toLowerCase() : '';
    return value != '' ?
      DataOptions.filter(option => (option.customerName.toLowerCase().includes(filterValue) || JSON.stringify(option.centreId).includes(filterValue))) : DataOptions;
  }

  entityLocationFilter(value, DataOptions) {
    const filterValue = value ? value.toLowerCase() : '';
    return value != '' ?
      DataOptions.filter(option => option.toLowerCase().includes(filterValue)) : DataOptions;
  }

}
