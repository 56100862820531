import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from 'src/app/config/environments/environment';
import { CommonServiceService } from 'src/app/core/common-service.service';
import { Subject } from 'rxjs';
import { PaginationModel } from 'src/app/modules/shared/model/pagination-model';

@Injectable({
  providedIn: 'root'
})
export class EntityServiceService {

  constructor(private http: HttpClient, private router: Router, private commonService: CommonServiceService) { }

   
  ///GET ALL Entity
  getAllEntity() {

 return this.http.get(`${environment.customerApiUrl}api/v1/entity`);

  }

  //GET ENTITY DETAILS
  getEntityDetails(entityCode)
  {
    return this.http.get(`${environment.customerApiUrl}api/v1/entity/entityCode/${entityCode}`);
  }

  //UPDATE ENTITY DETAILS
  updateEntityDetails(data)
  {
    return this.http.put(`${environment.customerApiUrl}api/v1/entity`,data);
  }

  //GET CUSTOMER LIST NOT IN ENTITY

  getCustomerListEntity()
  {
    return this.http.get(`${environment.customerApiUrl}api/v1/customers/approvedCustomerListing?page=0&searchText=&sortBy=&size=1000&sortDirection=ASC&status=`);
  }

  //GET BRANCH LIST BY CUSTOMER
  getBranchByCustomer(customerId)
  {
  return this.http.get(`${environment.customerApiUrl}api/v1/branch/picklist?customerId=${customerId}`);
  }

    //GET ENTITY  BY customer id
    getEntityByCustomer(customerId,roleName,region,city,cluster,center)
    {
      return this.http.get(`${environment.customerApiUrl}api/v1/entity/customerId/${customerId}/${roleName}?regionSite=${region?region:''}&citySite=${city?city:''}&clusterSite=${cluster?cluster:''}&centerSite=${center?center:''}`);
    }


}
